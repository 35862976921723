<template>
    <uw-content title="任务模板" padding="10px">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.dialogCreate.Open()">新的任务模板</el-button>
        </template>

        <!-- 表格 -->
        <uw-table :show-header="false" :show-footer="false">

            <!-- 数据 -->
            <template slot="data">
                <vxe-table ref="xTable" :data="table" height="100%">
                    <vxe-column width="60" type="seq" title="ID"></vxe-column>

                    <!-- 头像 -->
                    <vxe-column width="60">
                        <template #default="{ row }">
                            <el-tooltip effect="dark" :content="row.user.name" placement="top-start">
                                <el-avatar :size="26" style="margin-top: 5px" :src="row.user.avatar"> {{ row.user.name[0] }} </el-avatar>
                            </el-tooltip>
                        </template>
                    </vxe-column>

                    <!-- 名称 -->
                    <vxe-column field="name">
                        <template #default="{ row }">
                            <el-tag type="danger" size="mini" v-if="row.top">已置顶</el-tag>
                            {{ row.name }}W
                        </template>
                    </vxe-column>

                    <!-- 操作 -->
                    <vxe-column align="right" fixed="right" width="160">
                        <template #default="{ row }">

                            <el-link type="primary" class="w-margin-r-10" @click="$refs.dialogChange.Open(row.id)">模板</el-link>
                            <el-link type="primary" class="w-margin-r-10" @click="$refs.dialogUpdate.Open(row)">编辑</el-link>
                            
                            <!-- 移除确认 -->
                            <el-popconfirm title="确认要删除这个模板？" icon="el-icon-info" icon-color="red" @confirm="TableDelete(row.id)">
                                <el-link type="danger" slot="reference" class="w-margin-r-10">删除</el-link>
                            </el-popconfirm>
                        </template>
                    </vxe-column>

                </vxe-table>
            </template>
            
        </uw-table>

        <projects-template-update ref="dialogUpdate" @onSave="TableSearch()" />
        <projects-template-change ref="dialogChange" @onSave="TableSearch()" />
        <projects-template-create ref="dialogCreate" @onSave="TableSearch()" />
        
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            loading: false,

            table: [],
        }
    },

    mounted() {
        this.TableSearch()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.$http.post('/9api/projects-template/search-all', { append: ['user' ] }).then(rps => {
                this.table = rps.data
            })
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 移除
        |--------------------------------------------------
        |
        */

        TableDelete(id) {
            this.$http.post('/9api/projects-template/delete', { id: id }).then(rps => {
                this.TableSearch()
            })
        },
    }
}
</script>